import clsx from "clsx";
import moment from "moment";
import { ChangeEvent, FormEvent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DATE_FORMAT, DATE_TIME_FORMAT, MONTH_FORMAT } from "../../../helpers/constants";
import { ApiRequest } from "../../../helpers/types/api-types";
import ReactMultiSelect from "../../react-select/components/ReactMultiSelect";
import ReactSelect from "../../react-select/components/ReactSelect";
import "../table-filter-styles.scss"
import { DateTimePrecision, TableFilterFieldProps } from "../table-filter-types";

const TableFilterField = <Params extends ApiRequest>(props: TableFilterFieldProps<Params>) => {
  const {
    field,
    required,
    formik,
  } = props

  const { errors, touched } = formik;

  const FieldLabel = (): JSX.Element => {
    return (
      <>
        <label
          className={clsx(
            'form-label text-muted text-uppercase fw-semibold fs-7 ms-1',
            { 'required': required }
          )}
          hidden={field.hidden}
        >
          {field.label}
        </label>
        {errors[field.name] && touched[field.name] && (
          <OverlayTrigger
            placement='top'
             overlay={<Tooltip>{errors[field.name]?.toString().includes(field.name + " is a required field") ? field.label + " is required" : errors[field.name]?.toString()}</Tooltip>}
            delay={{ hide: 400, show: 0 }}
          >
            <span className='px-2'>
              <i className="fa-regular text-danger fa-circle-question"></i>
            </span>
          </OverlayTrigger>
        )}

        {field.hint &&
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{field.hint}</Tooltip>}
            delay={{ hide: 400, show: 0 }}
          >
            <span className='px-2'>
              <i className="fa-regular fa-circle-question"></i>
            </span>
          </OverlayTrigger>
        }
      </>
    )
  }

  const getCommonProps = () => ({
    name: field.name,
    placeholder: field.placeholder ?? `Search ${field.label.toLowerCase()}`,
    disabled: field.disabled,
    hidden: field.hidden,
  })

  const FieldInput = (): JSX.Element => {
    if (field.hidden) return <></>

    switch (field.type) {
      case "text":
        return <input
          type='text'
          {...formik.getFieldProps(field.name)}
          {...getCommonProps()}
          className='form-control form-control-solid'
          onInput={(event: FormEvent<HTMLInputElement>) => field.onChange?.(event as ChangeEvent<HTMLInputElement>)}
        />
      case "select":
        return <ReactSelect
          {...formik.getFieldProps(field.name)}
          {...getCommonProps()}
          options={field.options}
          onChange={onSelectChangeHandler}
          isLoading={field.isLoading}
        />
      case "multi-select":
        return <ReactMultiSelect
          {...formik.getFieldProps(field.name)}
          {...getCommonProps()}
          options={field.options}
          fixedValues={field.fixedValues}
          onChange={onMultiSelectChangeHandler}
          isLoading={field.isLoading}
        />
      case "date":
        return <input
          type='date'
          {...formik.getFieldProps(field.name)}
          {...getCommonProps()}
          className='form-control form-control-solid'
          onChange={onDateChangeHandler}
        />
      case "date-time":
        const precision: DateTimePrecision = field.precision ?? 's';
        return <input
          type='datetime-local'
          {...formik.getFieldProps(field.name)}
          {...getCommonProps()}
          className='form-control form-control-solid'
          step={precision === 's' ? 1 : 60} // the unit for step is "seconds"
          onChange={onDateChangeHandler}
        />
      case "month":
        return <input
            type='month'
            {...formik.getFieldProps(field.name)}
            {...getCommonProps()}
            className='form-control form-control-solid'
            onChange={onDateChangeHandler}
        />
    }
  }

  const onDateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue: string = event.currentTarget.value

    switch (field.type) {
      case 'date':
        newValue = moment(newValue).format(DATE_FORMAT)
        break
      case 'date-time':
        newValue = moment(newValue).format(DATE_TIME_FORMAT)
        break
      case 'month':
        newValue = moment(newValue).format(MONTH_FORMAT)
        break
      default:
        return
    }

    formik.setFieldValue(field.name, newValue)

    if (field.onChange !== undefined) field.onChange(event)

    if (field.dateRangeHandler !== undefined) {
      const handler = field.dateRangeHandler
      if (handler.condition(newValue)) {
        formik.setFieldValue(handler.targetInputName, handler.setTargetTo(newValue))
      }
    }
  }

  const onSelectChangeHandler = (newValue: string) => {
    if (field.type !== 'select') return

    formik.setFieldValue(field.name, newValue)

    if (field.onChange !== undefined) field.onChange(newValue)
  }

  const onMultiSelectChangeHandler = (newValue: string[]) => {
    if (field.type !== 'multi-select') return

    formik.setFieldValue(field.name, newValue)

    if (field.onChange !== undefined) field.onChange(newValue)
  }

  return (
    <>
      {field.newRow && <div className='row-break'></div>}
      <div className={`card-title mb-3 col-sm-12 col-md-6 col-lg-${field.colSizeLg ?? 3} offset-lg-${field.offsetLg ?? 0}`}>
        <FieldLabel />
        <div style={field.disabled ? { cursor: 'not-allowed' } : undefined}>
          {/* triggers more re-render when called as component, causing the component to appear blinking. */}
          {/* Not sure if this is caused by strict mode */}
          {/*<FieldInput />*/}
          {FieldInput()}
        </div>
      </div>
    </>
  )
}

export default TableFilterField
