/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from "../../../../app/modules/auth";

export function AsideMenuMain() {
  const intl = useIntl()
  const auth = useAuth()

  return (
    <>
      {/* Dashboard */}
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      {/* User */}
      {
        (auth.hasPermission('user-view') || auth.hasPermission('agent-view') || auth.hasPermission('sub-account-view') || auth.hasPermission('site-view') || auth.hasPermission('role-view') || auth.hasPermission('permission-view')) &&
        <AsideMenuItemWithSub
          to=''
          title='User Management'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/communication/com006.svg'
        >
          {
            auth.hasPermission('user-view') &&
            <AsideMenuItem to='/users' title='Users' hasBullet={true} />
          }
          {
            auth.hasPermission('agent-view') &&
            <AsideMenuItem to='/agents' title={intl.formatMessage({ id: 'MENU.AGENT' })} hasBullet={true} />
          }
          {
            auth.hasPermission('sub-account-view') &&
            <AsideMenuItem to='sub-accounts' title='Sub Accounts' hasBullet={true} />
          }
          {
            auth.hasPermission('site-view') &&
            <AsideMenuItem to='sites' title='Sites' hasBullet={true} />
          }
          {
            auth.hasPermission('role-view') &&
            <AsideMenuItem to='/roles' title='Roles' hasBullet={true} />
          }
          {
            auth.hasPermission('permission-view') &&
            <AsideMenuItem to='/permissions' title='Permissions' hasBullet={true} />
          }
        </AsideMenuItemWithSub>
      }

      {/* Position taking */}
      {
        (auth.hasPermission('company-pt-set') || auth.hasPermission('agent-site-pt-set')) &&
        <AsideMenuItemWithSub
          to=''
          title='Position Takings'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/graphs/gra010.svg'
        >
          {
            auth.hasPermission('company-pt-set') &&
            <AsideMenuItem to='position-taking/company' title='Company' hasBullet={true} />
          }
          {
            auth.hasPermission('agent-site-pt-set') &&
            <AsideMenuItem to='position-taking/agent-site' title='Site' hasBullet={true} />
          }
        </AsideMenuItemWithSub>
      }

      {/* Invoices */}
      {
        auth.hasPermission('invoice-view') &&
        <AsideMenuItem
          to='/invoices'
          title='Invoices'
          icon='/media/icons/duotune/ecommerce/ecm008.svg'
        />
      }

      {/* Finance Report */}
      {
        auth.hasPermission('finance-report') &&
        <AsideMenuItemWithSub
          to=''
          title='Finance'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/finance/fin007.svg'
        >
          <AsideMenuItem to='report/finance-report' title='Vendor Report' hasBullet={true} />
          <AsideMenuItem to='report/merchant-report' title='Vendor Breakdown Report' hasBullet={true} />
          <AsideMenuItem to='report/site-report' title='Site Report' hasBullet={true} />
          <AsideMenuItem to='report/premium-game-report' title='Site Premium Report' hasBullet={true} />
          <AsideMenuItem to='report/site-summary' title='Site Summary' hasBullet={true} />
          <AsideMenuItem to='report/txn-count' title='Txn Count Report' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      {/* Settlement Report */}
      {
        auth.hasPermission('settlement-report') &&
        <AsideMenuItemWithSub
          to=''
          title='Settlement'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen005.svg'
        >
          <AsideMenuItem to='report/settlement-index' title='Summary' hasBullet={true} />
          <AsideMenuItem to='report/settlement-tab' title='Daily Report' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      {/* Report Tools */}
      {
        auth.hasPermission('settlement-report') &&
        <AsideMenuItemWithSub
          to=''
          title='Report Tools'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen055.svg'
        >
          <AsideMenuItem to='report/settlement' title='Custom Report' hasBullet={true} />
          <AsideMenuItem to='report/report-by' title='Report By' hasBullet={true} />
          <AsideMenuItem to='report/transaction-list' title='User Transactions' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      {/* Report Status */}
      {
        auth.hasPermission('report-status') &&
        <AsideMenuItemWithSub
          to=''
          title='Report Status'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen032.svg'
        >
          <AsideMenuItem to='report/pdpd' title='PDPD' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      {/* Test Plan */}
      {
        (auth.hasPermission('test-template-view') || auth.hasPermission('test-session-view') || auth.hasPermission('test-result-view')) &&
        <AsideMenuItemWithSub
          to=''
          title='Test Plans'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/medicine/med005.svg'
        >
          {
            auth.hasPermission('test-template-view') &&
            <AsideMenuItem to='test-plan/templates' title='Test Templates' hasBullet={true} />
          }
          {
            auth.hasPermission('test-session-view') &&
            <AsideMenuItem to={'test-plan/sessions'} title='Test Sessions' hasBullet={true} />
          }
        </AsideMenuItemWithSub>
      }

      {/* Support */}
      {
        (auth.hasPermission('transfer-view')
          || auth.hasPermission('maintenance-view')
          || auth.hasPermission('game-view')) &&
        <AsideMenuItemWithSub
          to=''
          title='Support'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/coding/cod009.svg'
        >
          {
            auth.hasPermission('maintenance-view') &&
            <AsideMenuItem to='maintenance/provider' title='Provider Maintenances' hasBullet={true} />
          }
          {
            auth.hasPermission('maintenance-view') &&
            <AsideMenuItem to='maintenance/site-provider' title='Site Provider Maintenances' hasBullet={true} />
          }
          {
            auth.hasPermission('game-view') &&
            <AsideMenuItem to='games' title='Games' hasBullet={true} />
          }
          {
            auth.hasPermission('transfer-view') &&
            <AsideMenuItem to='transfers' title='Transfers' hasBullet={true} />
          }
        </AsideMenuItemWithSub>
      }

      {/* Free Round Bonus */}
      {
        auth.hasPermission('free-round-bonus-view') &&
        <AsideMenuItemWithSub
          to=''
          title='Free Round Bonus'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/finance/fin004.svg'
        >
          <AsideMenuItem to='/free-round-bonus' title='Index' hasBullet={true} />
          <AsideMenuItem to='/free-round-bonus-requests' title='FRB Requests' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      {/* Appendix */}
      {
        (auth.hasPermission('currency-view') || auth.hasPermission('game-provider-view')) &&
        <AsideMenuItemWithSub
          to=''
          title='Appendix'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/coding/cod001.svg'
          svg-icon svg-icon-success
        >
          {
            auth.hasPermission('currency-view') &&
            <AsideMenuItem to='currencies' title='Currencies' hasBullet={true} />
          }
          {
            auth.hasPermission('game-provider-view') &&
            <AsideMenuItem to='game-providers' title='Game Providers' hasBullet={true} />
          }
        </AsideMenuItemWithSub>
      }

      {/* API Management */}
      {
        (auth.hasPermission('api-key-view') || auth.hasPermission('cmsapi-url-view') || auth.hasPermission('callback-url-view') || auth.hasPermission('site-game-provider-view')) &&
        <AsideMenuItemWithSub
          to=''
          title='API Management'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil020.svg'
          svg-icon svg-icon-success
        >
          {
            (auth.hasPermission('api-key-view') || auth.hasPermission('cmsapi-url-view') || auth.hasPermission('callback-url-view')) &&
            <AsideMenuItem to='site-settings' title='Site Settings' hasBullet={true} />
          }
          {
            auth.hasPermission('site-game-provider-view') &&
            <AsideMenuItem to='site-game-provider' title='Site Game Provider' hasBullet={true} />
          }
        </AsideMenuItemWithSub>
      }

      {/* Logs */}
      {
        (auth.hasPermission('login-log-view') || auth.hasPermission('role-log-view') || auth.hasPermission('currency-log-view') || auth.hasPermission('permission-log-view')) &&
        <AsideMenuItemWithSub
          to=''
          title='Logs'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen005.svg'
        >
          {
            auth.hasPermission('login-log-view') &&
            <AsideMenuItem to='/log/login' title='Login Logs' hasBullet={true} />
          }
          {
            auth.hasPermission('role-log-view') &&
            <AsideMenuItem to='/log/role' title='Role Logs' hasBullet={true} />
          }
          {
            auth.hasPermission('currency-log-view') &&
            <AsideMenuItem to='/log/currency' title='Currency Logs' hasBullet={true} />
          }
          {
            auth.hasPermission('permission-log-view') &&
            <AsideMenuItem to='/log/permission' title='Permission Logs' hasBullet={true} />
          }
          {
            auth.hasPermission('transfer-log-view') &&
            <AsideMenuItem to='/log/transfer' title='Transfer Logs' hasBullet={true} />
          }
        </AsideMenuItemWithSub>
      }

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
